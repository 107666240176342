// Các trang báo trạng thái, kết quả,... "404", "505", "Thành công",...

const ResultLayout = ({ children }) => {
	return (
		<div className="result__layout">
			<div className="container"> {children}</div>
		</div>
	)
}

export default ResultLayout;
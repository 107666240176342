import { Button, Result } from 'antd'
import Link from 'next/link'
import ResultLayout from 'src/components/Layout/ResultLayout'

const Custom500 = () => {
	// MKD đề nghị chuyển hết thành lỗi 404
	return (
		<div className="custom__404">
			<Result
				title={
					<h1 style={{ color: 'var(--my-red)', margin: 0, opacity: 0.6, fontWeight: 500 }}>404</h1>
				}
				// eslint-disable-next-line @next/next/no-img-element
				icon={<img style={{ maxWidth: '90%', height: 250 }} src="/images/404.jpg" alt="404" />}
				//subTitle={<p style={{ fontSize: 16 }}>Opps! Đã xảy ra lỗi hệ thống, vui lòng thử lại sau.</p>}
				subTitle={<p style={{ fontSize: 16 }}>Trang bạn truy cập không tồn tại!</p>}
				extra={
					<Button style={{ height: 44, padding: '0px 32px' }} type="primary">
						<Link href="/">
							Quay lại trang chủ
						</Link>
					</Button>
				}
			/>
		</div>
	)
}

Custom500.Layout = ResultLayout
export default Custom500
